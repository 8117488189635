import {
  Button,
  Dropdown,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Select,
  Avatar,
  Tooltip,
  Badge,
  Upload,
} from 'antd';
import {
  UserOutlined,
  MenuOutlined,
  CarOutlined,
  ShopOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import React, { useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import DebounceInput from '../utility/DebounceInput';
import { useAxiosGet, useAxiosPost } from '../../configs/axios';
import useLocalStorage from '../../configs/localStorage';
import ProfileModal from './ProfileModal';
import {
  CreateOfflineOrder,
  makeCustomerVIP,
  uploadFibOrders,
} from '../../configs/constants';
import VIPButton from '../home/VIPButton';
import DriverForcastingButton from '../home/DriverForcasting';
import CreateTicketComponentButton from '../home/CreateTicketComponentButton';

const { Search } = Input;

const HeaderContent = (props) => {
  const [auth, setAuth] = useLocalStorage('auth', null);
  const permissions = auth?.user.role.permissions.map((per) => per.name);
  const { toggleSidebar } = props;
  const [searchType, setSearchType] = useState('orderId');
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [customerCharge, setCustomerCharge] = useState(0);
  const [merchantCharge, setMerchantCharge] = useState(0);
  const [cityZones, setCityZones] = useState([]);
  const [cityZoneId, setCityZoneId] = useState(0);
  const [form] = Form.useForm();
  const [fibForm] = Form.useForm();
  const [offlineOrderModalVisible, setOfflineOrderModalVisible] =
    useState(false);

  const [uploadFIBOrdersModalVisible, setUploadFIBOrdersModalVisible] =
    useState(false);

  const { request: getMerchantDeliveryPrice } = useAxiosGet(
    '/merchants/deliveryOffline',
  );

  const { request: getAllMerchants } = useAxiosGet('/merchants');
  const { request: getCitZones } = useAxiosGet('/cityZones');
  const { loading: offlineOrderLoading, request: offlineOrderRequest } =
    useAxiosPost('/orders/offline');

  const { loading: fibLoading, request: uploadFIBCsv } = useAxiosPost('/fib');

  const [merchantSearchValue, setMerchantSearchValue] = useState(null);
  const fetchMerchantsDropdown = async (searchQuery) => {
    const response = await getAllMerchants({ search: searchQuery });

    return response.map((merchant) => ({
      label: `${merchant.merchant_id} - ${merchant.merchant_name} - ${merchant.merchant_city}`,
      value: JSON.stringify(merchant),
    }));
  };

  useEffect(() => {
    getCitZones()
      .then((data) => {
        setCityZones(data || []);
      })
      .catch((err) => {
        console.log('🚀 ~ file: HeaderContent.jsx:61 ~ useEffect ~ err', err);
      });
  }, []);

  useEffect(() => {
    if (cityZoneId && merchantSearchValue && merchantSearchValue.length) {
      getMerchantDeliveryPrice({
        zoneId: cityZoneId,
        merchantId: JSON.parse(merchantSearchValue[0].value).merchant_id,
      })
        .then((data) => {
          setMerchantCharge(data.merchantCharge);
          setCustomerCharge(data.customerCharge);
        })
        .catch((error) => {
          console.log(
            '🚀 ~ file: HeaderContent.jsx:81 ~ useEffect ~ error',
            error,
          );
          setMerchantCharge(0);
          setCustomerCharge(0);
        });
    }
  }, [cityZoneId]);

  const onOfflineOrderSubmit = async (values) => {
    const requestBody = {
      ...values,
      orderDeliveryPrice: customerCharge || 0,
      orderMerchantDelivery: merchantCharge || 0,
      orderMerchant: JSON.parse(values.orderMerchant[0].value).merchant_id,
    };

    try {
      await offlineOrderRequest(requestBody);
      setOfflineOrderModalVisible(false);
      message.success('Offline order created successfully');
      location.reload();
    } catch (error) {
      message.error('Error creating offline order');
    }
  };

  const onUploadCSVSubmit = async (values) => {
    if (!(values && values.fibCustomers)) {
      return true;
    }

    try {
      const formData = new FormData();
      formData.append('fibCustomers', values.fibCustomers[0].originFileObj);
      await uploadFIBCsv(formData);
      message.success('Offline order created successfully');
      location.reload();
    } catch (error) {
      message.error('Error Uploading FIB Orders');
    }
  };

  const { request: logoutRequest } = useAxiosPost('/auth/logout');
  return (
    <div
      style={{
        height: '64px',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '80%',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
        }}
      >
        <Button
          type="primary"
          shape="circle"
          icon={<MenuOutlined />}
          onClick={() => toggleSidebar()}
        />

        <Search
          style={{
            width: '100%',
          }}
          addonBefore={
            <Select
              defaultValue="orderId"
              onChange={(value) => {
                setSearchType(value);
              }}
              style={{
                width: '120px',
              }}
            >
              <Select.Option value="orderId">Order Id</Select.Option>
              <Select.Option value="orderHex">Order Hex</Select.Option>
            </Select>
          }
          placeholder="Search orders"
          enterButton
          size="large"
          onSearch={(value) => {
            if (!value) return;
            let searchValue = value;
            if (searchType === 'orderHex') {
              searchValue = parseInt(searchValue, 16);
            } else {
              searchValue = parseInt(value, 10);
            }
            window.open(
              `${window.location.origin}/order-details?oid=${searchValue}`,
              '_blank',
              'noopener,noreferrer',
            );
          }}
        />
        {_.includes(permissions, CreateOfflineOrder) ? (
          <Button
            size="large"
            type="primary"
            style={{
              borderRadius: '60px',
            }}
            onClick={() => setOfflineOrderModalVisible(true)}
          >
            Offline Order
          </Button>
        ) : null}

        {_.includes(permissions, uploadFibOrders) ? (
          <Button
            size="large"
            type="primary"
            style={{
              borderRadius: '60px',
            }}
            onClick={() => setUploadFIBOrdersModalVisible(true)}
          >
            FIB
          </Button>
        ) : null}

        {_.includes(permissions, makeCustomerVIP) ? <VIPButton /> : null}

        {_.includes(permissions, uploadFibOrders) ? (
          <DriverForcastingButton />
        ) : null}
        <CreateTicketComponentButton />
      </div>

      <Modal
        title="Offline Order"
        destroyOnClose
        open={offlineOrderModalVisible}
        onOk={() => {
          form.submit();
        }}
        okButtonProps={{
          loading: offlineOrderLoading,
        }}
        onCancel={() => {
          setOfflineOrderModalVisible(false);
        }}
      >
        <Form form={form} layout="vertical" onFinish={onOfflineOrderSubmit}>
          <Form.Item
            label="Merchant"
            name="orderMerchant"
            rules={[
              {
                required: true,
                message: 'Please select a merchant',
              },
            ]}
          >
            <DebounceInput
              value={merchantSearchValue}
              placeholder="Filter by merchant"
              fetchOptions={fetchMerchantsDropdown}
              onChange={(newValue) => {
                if (newValue?.length > 1) {
                  return newValue.pop();
                }

                setMerchantSearchValue(newValue);
                return true;
              }}
            />
          </Form.Item>
          <Form.Item label="City Zone" name="cityZone">
            <Select
              disabled={
                !(merchantSearchValue && merchantSearchValue.length > 0)
              }
              onChange={(val) => {
                if (val && val.length) {
                  const zoneId = val.substring(
                    val.indexOf('#') + 1,
                    val.lastIndexOf('#'),
                  );
                  setCityZoneId(parseInt(zoneId, 10));
                } else {
                  setMerchantCharge(0);
                  setCustomerCharge(0);
                }
              }}
              placeholder="Select City Zone"
              optionLabelProp="children"
              showSearch
              allowClear
              options={
                cityZones && cityZones.length
                  ? cityZones?.map((hub) => ({
                      label: `#${hub.cityZoneId}# ${hub.zoneName}`,
                      value: `#${hub.cityZoneId}# ${hub.zoneName}`,
                    }))
                  : []
              }
            />
          </Form.Item>

          <Form.Item label="Order Location" name="orderAddress">
            <Input placeholder="E.g. Sarwaran" />
          </Form.Item>
          <Form.Item label="Total Price" name="orderPrice">
            <Input type="number" placeholder="E.g. 1000" addonAfter="IQD" />
          </Form.Item>
          <p> Customer Delivery</p>
          <InputNumber
            placeholder="E.g. 1000"
            addonAfter="IQD"
            max={100000}
            value={customerCharge}
            onChange={(val) => {
              setCustomerCharge(val || 0);
            }}
          />
          <br />
          <br />
          <p> Merchant Delivery</p>
          <InputNumber
            placeholder="E.g. 1000"
            addonAfter="IQD"
            max={100000}
            value={merchantCharge}
            onChange={(val) => {
              setMerchantCharge(val || 0);
            }}
          />
        </Form>
      </Modal>

      <Modal
        title="Upload FIB Orders"
        destroyOnClose
        open={uploadFIBOrdersModalVisible}
        onOk={() => {
          fibForm.submit();
        }}
        okButtonProps={{
          loading: fibLoading,
        }}
        onCancel={() => {
          setUploadFIBOrdersModalVisible(false);
        }}
      >
        <Form
          loading={true}
          form={fibForm}
          layout="vertical"
          onFinish={onUploadCSVSubmit}
        >
          <Form.Item
            name="fibCustomers"
            label="CSV File"
            valuePropName="fileList"
            getValueFromEvent={(e) => {
              if (Array.isArray(e)) {
                return e;
              }
              return e && e.fileList;
            }}
          >
            <Upload
              showUploadList={false}
              accept=".csv"
              maxCount={1}
              beforeUpload={() => false}
            >
              <Button loading={fibLoading} icon={<UploadOutlined />}>
                Upload CSV File
              </Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '20px',
          height: '100%',
        }}
      >
        <ProfileModal
          status={profileModalVisible}
          closeModal={() => setProfileModalVisible(false)}
        />
        <Dropdown
          menu={{
            items: [
              {
                label: (
                  <a
                    onClick={() => {
                      setProfileModalVisible(true);
                    }}
                  >
                    Profile
                  </a>
                ),
                key: '6',
              },

              {
                type: 'divider',
              },
              {
                label: (
                  <a
                    onClick={async () => {
                      try {
                        await logoutRequest();
                        setAuth(null);
                        location.replace('/login');
                      } catch (error) {
                        console.log(
                          '🚀 ~ file: HeaderContent.jsx:395 ~ onClick={ ~ error',
                          error,
                        );
                        message.error('Error logging out');
                      }
                    }}
                  >
                    Logout
                  </a>
                ),
                key: '3',
              },
            ],
          }}
          style={{
            cursor: 'pointer',
          }}
        >
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              flexDirection: 'row',
           
            }}
          >
            <Avatar
              style={{
                marginRight: 10,
                color: 'white',
                backgroundColor: 'rgb(0,100,192)',
              }}
              size={40}
              icon={<UserOutlined />}
            />

            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent:'center',
              gap: 4,
              lineHeight: 1.1
            }}>
              <span >{auth?.user?.userName}</span>
              <span style={{
                fontSize: 9,
                color:"#878787"
              }}>V1.0.27</span>
            </div>
          </div>
        </Dropdown>
      </div>
    </div>
  );
};

export default HeaderContent;
